import { render, staticRenderFns } from "./WidgetKeyValue.vue?vue&type=template&id=3b6f6f42"
import script from "./WidgetKeyValue.vue?vue&type=script&setup=true&lang=ts"
export * from "./WidgetKeyValue.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./WidgetKeyValue.vue?vue&type=style&index=0&id=3b6f6f42&prod&module=true&lang=css"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CompositionBackgroundCard: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/Composition/CompositionBackgroundCard.vue').default,Copy: require('/app/node_modules/@estaid/ui/dist/runtime/atoms/Copy/Copy.vue').default,Icon: require('/app/node_modules/@estaid/ui/dist/runtime/atoms/Icon/Icon.vue').default,LoaderCopy: require('/app/components/Loader/LoaderCopy.vue').default,Label: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/Label/Label.vue').default,KeyValuePair: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/KeyValuePair/KeyValuePair.vue').default,Cta: require('/app/components/Widget/components/Cta.vue').default,CompositionLayoutSingle: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/Composition/CompositionLayoutSingle.vue').default,Composition: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/Composition/Composition.vue').default})
